<template>
  <div class="content">
    <div class="Viewright">
      <img src="../assets/image/Viewright.png" alt=""  class="ViewrightBox"/>
    </div>
    <div class="circular">
      <img src="../assets/image/circular.png" alt=""  class="ViewrightBox"/>
    </div>
    <div class="typeArea">
      <!-- <div class="one"></div> -->
      <div class="typeArea-top">
        <div class="top">
          <div class="loginBox">
            <img src="@/assets/image/login.png" alt="" class="logoBox"/>
          </div>
          <div class="top-right">
            <div
              v-for="(item, index) in topTitleList"
              :key="index"
              @click="topBtn(index)"
              @mouseenter="handleMouseTop(index)"
              @mouseleave="handleMouseTopBtn(index)"
            >
              <a
                :href="item.anchor"
                :class="item.show ? item.className : item.className_open"
              >
                {{ item.title }}</a
              >
            </div>
            <!-- <a
              href=""
              class="About"
              target="_blank"
              >Whitepaper</a
            > -->
          </div>
        </div>
      </div>
      <a href="" name="About"></a>
      <!--  -->
      <div class="fitnessBox">
        <div class="fitnessBox-one">
          <div>
            <div class="fitness_to_earn">Fitness To Earn</div>
            <div class="divisionBox">
              <img src="../assets/image/division.gif" alt="" class="divisionImage"/>
            </div>
            <div class="text">
              MOJU - the inaugural WEB3.0 wearable app featuring a
              'fitness-to-earn' capability. Track calories, real-time heart
              rate, blood pressure, blood oxygen, and sleep. A fusion of Web 2.0
              and Web 3.0, MOJU commits to an open and extensive health economy.
              Users earn tokens through various fitness activities, fostering
              collaboration among developers, creators, contributors, consumers,
              and investors
            </div>
            <div class="downloadBox">
              <div class="appStoreBox">
                <img src="../assets/image/appStore.png" alt="" class="appStoreImage"/>
              </div>
              <div class="googleBox">
                <img src="../assets/image/google.png" alt="" class="googleImage"/>
              </div>
            </div>
          </div>
          <div class="watchBox animate__animated animate__bounceIn">
            <!-- <img src="../assets/image/watch.png" alt="" /> -->
            <img src="../assets/image/newWatch.gif" alt="" class="newWatch"/>

          </div>
        </div>
        <!--  -->
        <div class="Ecosystem">
          <div class="title">Ecosystem & Features</div>
          <div class="divisionBox">
            <img src="../assets/image/division.gif" alt="" class="divisionImage" />
          </div>
          <div class="features">
            <div v-for="(item, index) in EcosystemList" :key="index">
              <div
                :class="item.show ? item.className : item.className_open"
                @mouseenter="handleMouseEcosystem(index)"
                @mouseleave="handleMouseLeaveEcosystem(index)"
              >
                <div class="ecosyImageBox">
                  <img :src="item.image" alt="" class="ecosyImage"/>
                </div>
                <div class="EcosystemTitle">{{ item.title }}</div>
                <div class="EcosystemText">{{ item.text }}</div>
              </div>
              <div
                :class="
                  item.show
                    ? item.EcosystemBottomClass
                    : item.EcosystemBottomClass_open
                "
              ></div>
              
            </div>
            <!-- <div class="wire"></div> -->
          </div>
        </div>
        <!--  -->
        <a href="" name="how"></a>
        <div class="startedBox">
          <div class="title">Let’s get you started</div>
          <div class="divisionBox">
            <img src="../assets/image/division.gif" alt=""  class="divisionImage"/>
          </div>
          <div class="dowStarted">
            <div class="downlowMOJU">
              <div class="downlowMOJU-l">
                <div class="downlowMOJU-l-one">
                  <div class="numBox">
                    1
                    <div class="numBoxPos"></div>
                  </div>
                  <div class="dowTitle">Download MOJU</div>
                </div>
                <div class="dowText">
                  Download the MOJU app from the App Store or Google Play, and
                  register your account using your email.
                </div>
              </div>
              <div class="downlowMOJU-l-TWO">
                <div class="appStoreBtn">
                  <img src="../assets/image/appStore.png" alt=""  class="appStoreBtnImage"/>
                </div>
                <div class="gooleBtn">
                  <img src="../assets/image/google.png" alt="" class="gooleBtnImage"/>
                </div>
              </div>
            </div>
            <div class="r-icomn">
              <img src="../assets/image/r-icon.png" alt="" />
            </div>
            <!-- r -->
            <div class="downlowMOJU">
              <div class="downlowMOJU-l">
                <div class="downlowMOJU-l-one">
                  <div class="numBox">
                    2
                    <div class="numBoxPos"></div>
                  </div>
                  <div class="dowTitle">Create A Wallet</div>
                </div>
                <div class="dowText" style="width: 308px">
                  Generate a fresh wallet account within the app (remember to
                  safeguard your private key and refrain from sharing it with
                  anyone).
                </div>
              </div>
              <div class="downlowMOJU-r">
                <img src="../assets/image/two.png" alt="" class="appStoreBtnImage"/>
              </div>
            </div>
            <!-- r end -->
          </div>
          <!--  -->
          <div class="dowStarted">
            <div class="downlowMOJU">
              <div class="downlowMOJU-l">
                <div class="downlowMOJU-l-one">
                  <div class="numBox">
                    3
                    <div class="numBoxPos"></div>
                  </div>
                  <div class="dowTitle">Connect Watch</div>
                </div>
                <div class="dowText">
                  Connect the MOJU smartwatch to the MOJU APP via Bluetooth to
                  display and retrieve various exercise data for seamless
                  integration.
                </div>
              </div>
              <div class="downlowMOJU-l-three">
                <img src="../assets/image/three.png" alt=""  class="appStoreBtnImage"/>
              </div>
            </div>
            <div class="r-icomn">
              <img src="../assets/image/r-icon.png" alt="" />
            </div>
            <!-- r -->
            <div class="downlowMOJU">
              <div class="downlowMOJU-l">
                <div class="downlowMOJU-l-one">
                  <div class="numBox">
                    4
                    <div class="numBoxPos"></div>
                  </div>
                  <div class="dowTitle">Earn Profits</div>
                </div>
                <div class="dowText" style="width: 308px">
                  Embark on your fitness journey to tokenize walks or runs, burning calories and earning $MOJU.
                </div>
              </div>
              <div class="downlowMOJU-l-four">
                <img src="../assets/image/four.png" alt=""  class="appStoreBtnImage"/>
              </div>
            </div>
            <!-- r end -->
          </div>
          <!-- end -->
        </div>
      </div>
      <!--  -->
      <div class="GameBox">
        <div class="GameTitle">
          Game Modes
          <div class="gamePosIcon">
            <img src="../assets/image/gamePos.png" alt="" />
          </div>
        </div>
        <div class="divisionBox">
          <img src="../assets/image/division.gif" alt=""  class="divisionImage"/>
        </div>
        <!--  -->
      </div>
      <div class="modesBox" ref="scrollContainer">
        <div 
        class="CeshimodesBox"
        ref="conter"
        @mouseenter="pauseAnimation"
        @mouseleave="resumeAnimation"
         >
              <div
                ref=""
                class="soloModeBox"
                v-for="(item, index) in modesBoxList"
                :key="index"
              >
                <div class="soloModeBox-top">
                  <div :class="item.className">
                    <img :src="item.image" alt=""  class="appStoreBtnImage"/>
                  </div>
                </div>
                <div class="soloTitle">{{ item.title }}</div>
                <div class="solotext">{{ item.text }}</div>
                <div class="line-with-dots"></div>
              </div>
        </div>
      </div>
      <!-- end -->
      <!--  -->
      <div class="videoBox">
        <div class="playBox">
          <video
          controls
          src="../assets/image/264_1700041205.mp4"
          class="view"
          type="video/mp4"
          preload="auto"
          loop
          autoplay
        ></video> 
        <div class="videBottom">
          <img src="../assets/image/mohu.png" alt="" class="appStoreBtnImage"/>
        </div>
        </div>
      </div>
      <!-- end -->
      <!--  -->
      <a href="" name="Token"></a>
      <div class="theBox">
        <div>
          <div class="title" style="margin-top: 8px">THE TOKEN</div>
          <div class="divisionBox">
            <img src="../assets/image/division.gif" alt="" class="divisionImage"/>
          </div>
          <div class="theText">
            $MOJU is the governance token of MOJU ecosystem, which can be used
            to promote the economic development of the platform and governance
            of the ecosystem.
          </div>
        </div>
        <div>
          <div class="tmjcImage">
            <img src="../assets/image/tmjc.png" alt=""  class="appStoreBtnImage"/>
            <div class="ballImage">
              <img src="../assets/image/ball.png" alt="" class="appStoreBtnImage"/>
            </div>
          </div>
        </div>
      </div>
      <!--  -->
      <!--  -->
      <div class="NFTExplainBox">

        <div class="NTF" v-for="(item,index) in NFTExplainBoxList" :key="index">
          <div class="hammer">
            <img src="../assets/image/hammer.png" alt="" class="appStoreBtnImage" />
          </div>
          <div class="NTFText">{{ item.title }}</div>
        </div>

      </div>
      <!-- end -->
      <!--  -->
      <div class="TOKENOMICS">
        <div class="title">$MOJU TOKENOMICS</div>
        <div class="divisionBox">
          <img src="../assets/image/division.gif" alt=""  class="divisionImage"/>
        </div>
        <div class="diagram">
          <div class="lineChart">
            <div id="line_chart"></div>
          </div>
          <div class="chart">
            <div id="pie_chart" style="width: 218px; height: 218px"></div>
            <!--  -->
            <div class="pie_chart-buttom">
              <!-- begin -->
              <div class="r-seed-box">
                <div class="r-seed-box1">
                  <div class="r-seed"></div>
                  <div>Seed</div>
                </div>
                <div>3%</div>
              </div>
              <div class="r-seed-box">
                <div class="r-seed-box1">
                  <div class="r-seed r-private"></div>
                  <div>Private</div>
                </div>
                <div>10%</div>
              </div>
              <div class="r-seed-box">
                <div class="r-seed-box1">
                  <div class="r-seed r-public"></div>
                  <div>Public</div>
                </div>
                <div>2%</div>
              </div>
              <div class="r-seed-box">
                <div class="r-seed-box1">
                  <div class="r-seed r-Foundation"></div>
                  <div>Foundation</div>
                </div>
                <div>10%</div>
              </div>

              <div class="r-seed-box">
                <div class="r-seed-box1">
                  <div class="r-seed r-Community"></div>
                  <div>Community</div>
                </div>
                <div>5%</div>
              </div>
              <div class="r-seed-box">
                <div class="r-seed-box1">
                  <div class="r-seed r-Marketing"></div>
                  <div>Marketing</div>
                </div>
                <div>7%</div>
              </div>
              <!-- <div class="r-seed-box">
                    <div class="r-seed-box1">
                        <div class="r-seed r-Foundation"></div>
                        <div>Foundation</div>
                    </div>
                    <div>10%</div>
                </div> -->
              <div class="r-seed-box">
                <div class="r-seed-box1">
                  <div class="r-seed r-Team"></div>
                  <div>Team</div>
                </div>
                <div>15%</div>
              </div>

              <div class="r-seed-box">
                <div class="r-seed-box1">
                  <div class="r-seed r-Move"></div>
                  <div>Fitness To Earn</div>
                </div>
                <div>30%</div>
              </div>
              <div class="r-seed-box">
                <div class="r-seed-box1">
                  <div class="r-seed r-Ecosystem"></div>
                  <div>Ecosystem</div>
                </div>
                <div>8%</div>
              </div>
              <div class="r-seed-box">
                <div class="r-seed-box1">
                  <div class="r-seed r-Liquidity"></div>
                  <div>Liquidity</div>
                </div>
                <div>10%</div>
              </div>
            </div>

            <!-- end -->
          </div>
        </div>
      </div>
      <!-- end -->
      <!--  -->
      <a href="" name="Roadmap"></a>
      <div class="startedBox">
        <div class="title">Our Roadmap</div>
        <div class="divisionBox">
          <img src="../assets/image/division.gif" alt=""  class="divisionImage"/>
        </div>
      </div>
      <!-- end -->
    </div>
    <div class="quicklyBox">
      <div class="quicklyBoxConter">
         <img src="../assets/image/mapConter.png" alt="" class="quicklyImage" />
      </div>
     
    </div>
    <!--  -->
    <div class="typeAreaBottom">
      <a href="" name="Team"></a>
      <!--  -->
      <div class="TeamBox">
        <div class="title">Our Team</div>
        <div style="margin-bottom: 20px" class="divisionBox">
          <img src="../assets/image/division.gif" alt="" class="divisionImage" />
        </div>
        <div class="teamIntroduce">
          <div class="CEOBox" v-for="(item, index) in teamList" :key="index">
            <div class="ceoImage">
              <img :src="item.head" alt="" class="appStoreBtnImage"/>
            </div>
            <div class="characters">
              <div class="ceotitle">{{ item.title }}</div>
              <div class="ceoText">{{ item.text }}</div>
            </div>
          </div>
        </div>
      </div>
      <!-- end -->
      <!--  -->
      <div class="bottomBigBox">
        <div class="bottomBox">
          <div class="bottomLoginImage">
            <img src="../assets/image/bottomLogin.png" alt="" class="appStoreBtnImage"/>
          </div>
          <div>
            <div class="Social">Social</div>
            <div class="Social-bottom">
              <div class="circulation">
                <div
                  v-for="(item, index) in bottomIconList"
                  :key="index"
                  @mouseenter="handleMouseEnter(index)"
                  @mouseleave="handleMouseLeave(index)"
                  @click="bottomBtn(index)"
                  :class="item.show ? 'bottomimage' : 'bottomimageopen'"
                >
                  <img
                    :src="item.show ? item.picture : item.picture_open"
                    alt="Image"
                    class="transi"
                  />
                </div>
              </div>
              <div class="social7">
                <img src="../assets/image/social7.png" alt=""  class="appStoreBtnImage"/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end -->
      <!--  -->
      <div class="PrivacyBox">
        <div class="Privacy">
          <div class="reserved">@ All rights reserved.</div>
          <div>
            <a class="APolicy" href="https://h5.mojufit.com/privacy-en.html"
              >《《Privacy Policy》》</a
            >
          </div>
        </div>
      </div>
      <!-- end -->
    </div>
    <!--  end-->
    <div class="bottomBox-l"></div>
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      intervalId:'',//定时器名称
      currentBox:0,
      topIndex:0,
      translateX:0,
      startX:0,
      isDragging:false,
      containerWidth: 48, // 宽
      containerHeight: 53, // 高
      NFTExplainBoxList:[
        {
          title:"Level Up NFT"
        },
        {
          title:"Rent & Rewards"
        },
        {
          title:"Governance"
        },
        {
          title:"Distribution"
        },
        {
          title:"Transactions"
        },
        {
          title:"Marketplace"
        },
        {
          title:"Staking"
        },
      ],
      modesBoxList: [
        {
          image: require("../assets/image/solo.png"),
          className:'soloImage',
          title: "Solo Mode",
          text: " Enthusiastic fitness individuals can freely select their preferred sports projects and training intensities based on their abilities and preferences—whether it's running, weightlifting or aerobic exercise (Burning Calories) —all while earning $MOJU.",
        },
        {
          image: require("../assets/image/versus.png"),
          title: "Versus Mode",
          className:'soloImage',
          text: " Players engage in face-to-face competitions, invite friends, or  participate in online matches with other fitness enthusiasts in the same type of exercise for a race. Rewards are based on performance in the activity, with the winning party earning additional token rewards.",
        },
        {
          image: require("../assets/image/championship.png"),
          title: "Championship",
          className:'soloImage',
          text: "The championship presents a challenging yet rewarding opportunity, where successfully completing designated sports projects and tasks within a predefined timeframe allows participants to showcasetheir prowess and earn substantial rewards.",
        },
        {
          image: require("../assets/image/task.png"),
          title: "Task Mode",
          className:'TaskBox',
          text: "The task system enhances the allure and motivation of fitness. Categorized into main, side, daily, random, and time-limitedtasks, it ensures you can find suitable objectives for various occasions and needs.",
        },
      ],
      topTitleList: [
        {
          anchor: "#About",
          className: "About",
          className_open: "About-open",
          title: "About",
          show: false,
        },
       
        {
          anchor: "#how",
          title: "How to play",
          className: "About",
          className_open: "About-open",
          show: true,
        },
         {
          anchor: "#Token",
          className: "About",
          className_open: "About-open",
          title: "Token",
          show: true,
        },
        {
          anchor: "#Roadmap",
          title: "Roadmap",
          className: "About",
          className_open: "About-open",
          show: true,
        },
        {
          anchor: "#Team",
          title: "Team",
          className: "About",
          className_open: "About-open",
          show: true,
        },
         {
          anchor:'',
          title:'Whitepaper',
          className:'About',
          className_open:"About-open",
           show:true
        },
      ],
      EcosystemList: [
        {
          image: require("../assets/image/FIT.png"),
          title: "FIT-WATCH",
          text: "MOJU WATCH monitors users' real-time movement and health data, including blood oxygen levels, heart rate, steps, calories, etc., to assess and evaluate fitness performance.",
          className: "features-top",
          className_open: "features-topOpen",
          show: true,
          EcosystemBottomClass: "EcosystemBottom",
          EcosystemBottomClass_open: "EcosystemBottom-open",
        },
        {
          image: require("../assets/image/FitnessFI-top.png"),
          title: "FitnessFI",
          text: "Earn token rewards by engaging in fitness activities such as walking, running, playing sports, swimming, and more across various game modes.",
          className: "features-topTwo",
          className_open: "features-topTwoOpen",
          EcosystemBottomClass: "EcosystemBottom",
          EcosystemBottomClass_open: "EcosystemBottom-open",
          show: true,
        },
        {
          image: require("../assets/image/SocialFI-top.png"),
          title: "SocialFi",
          text: "Participate in community activities, share fitness achievements, and comment on others' workout records to actively build a close fitness social network.",
          className: "features-topThree",
          className_open: "features-topThreeOpen",
          EcosystemBottomClass: "EcosystemBottom",
          EcosystemBottomClass_open: "EcosystemBottom-open",
          show: true,
        },
        {
          image: require("../assets/image/Character-top.png"),
          title: "Character",
          text: "The MOJU characters are NFT virtual entities with unique functionalities, immersing users in a digital realm that goes beyond traditional experiences.",
          className: "features-topFour",
          className_open: "features-topFourOpen",
          EcosystemBottomClass: "EcosystemBottom",
          EcosystemBottomClass_open: "EcosystemBottom-open",
          show: true,
        },
      ],
      // 底部图标
      bottomIconList: [
        {
          picture: require("../assets/image/social1.png"),
          picture_open: require("../assets/image/social1-open.png"),
          show: true,
        },
        {
          picture: require("../assets/image/social2.png"),
          picture_open: require("../assets/image/social2-open.png"),
          show: true,
        },
        {
          picture: require("../assets/image/social3.png"),
          picture_open: require("../assets/image/social3-open.png"),
          show: true,
        },
        {
          picture: require("../assets/image/social4.png"),
          picture_open: require("../assets/image/social4-open.png"),
          show: true,
        },
        {
          picture: require("../assets/image/social5.png"),
          picture_open: require("../assets/image/social5-open.png"),
          show: true,
        },
        {
          picture: require("../assets/image/social6.png"),
          picture_open: require("../assets/image/social6-open.png"),
          show: true,
        },
      ],
      teamList: [
        {
          head: require("../assets/image/ceo.png"),
          title: "CEO - Justin Wilhite",
          text: "MBA, 25 years in prominent companies, founder of successful Internet 020 project. Expertise and leadership enrich our team.",
        },
        {
          head: require("../assets/image/cfo.png"),
          title: "CFO - Olivia Parker",
          text: "Bringing a wealth of expertise garnered from years in the investment banking industry, making them a pivotal asset to our financial team.",
        },
        {
          head: require("../assets/image/cto.png"),
          title: "CTO - Gang",
          text: "Brings 7 years at a top telecom and 8 years in internet tech. Expert in software management and architecture, he vital to our web3 blockchain venture.",
        },
        {
          head: require("../assets/image/coo.png"),
          title: "COO - Henry Tan",
          text: "With 15 years at a top telecom, specialized in software, hardware, IoT, cloud, and big data. Notably, co-founded a Web2 project with 40M users.",
        },
        {
          head: require("../assets/image/jason.png"),
          title: "Jason Jones",
          text: "3 years of experience in the Web3 community, encompassing responsibilities in user operations, financing, investment research, and various other key roles.",
        },
        {
          head: require("../assets/image/harper.png"),
          title: "Harper",
          text: "5 years in Web2 operations, managing projects with 1M+ DAU. Additionally, holds 2 years of specialized expertise in Web3 project resource management within the blockchain domain.",
        },
        {
          head: require("../assets/image/naah.png"),
          title: "Noah Harris",
          text: "5 years in Web2 operations, managing projects with 1M+ DAU. Additionally, holds 2 years of specialized expertise in Web3 project resource management within the blockchain domain.",
        },
        {
          head: require("../assets/image/dan.png"),
          title: "Don",
          text: "2 years of experience in cryptocurrency exchange projects, successfully overseeing projects with over one million users.",
        },
      ],
    };
  },
  mounted() {
    this.intPie();
    this.intLine();
    
  },
  created(){
    this.startAnimation()
  },
  methods: {
    //顶部导航鼠标经过
    handleMouseTop(e){
       for (let i in this.topTitleList) {
        this.topTitleList[i].show = true;
      }
      this.topTitleList[e].show = false;
    },
    //鼠标离开
    handleMouseTopBtn(e){
      this.topTitleList[e].show = true
      this.topTitleList[this.topIndex].show = false
      // console.log('离开',this);
    },
     pauseAnimation() {
        this.$refs.conter.style.animationPlayState = 'paused';
    },
    resumeAnimation(){
      this.$refs.conter.style.animationPlayState = 'running';
    },
    // 底部导航
    bottomBtn(e){
      let url = ''
      switch (e) {
        case 0:
           url = 'https://t.me/MOJU_Global'
          break;
        case 1:
           url = 'https://discord.gg/nj2Hf9AMYz'
          break;
      case 2:
           url = 'https://moju-official.medium.com'
          break;
        case 3:
           url = 'https://youtube.com/@MOJUofficial'
          break;
        case 4:
           url = 'https://hongs-organization-2.gitbook.io/moju-fitness-to-earn/'
          break;
        case 5:
           url = 'https://twitter.com/mojuofficial'
          break;
        default:
          break;
      }
      window.open(url)
    },
    handleMouseDown(event){
      this.isDragging = true;
      this.startX = event.clientX - this.translateX;
    },
    handleMouseMove(event){
      if (this.isDragging) {
        const offsetX = event.clientX - this.startX;
        this.translateX = offsetX;
      }
    },
    handleMouseUp(){
      this.isDragging = false;
    },
    topBtn(e) {
      for (let i in this.topTitleList) {
        this.topTitleList[i].show = true;
      }
      this.topTitleList[e].show = false;
      this.topIndex = e
      if (e==5) {
        const url = 'https://hongs-organization-2.gitbook.io/moju-fitness-to-earn/'
        window.open(url)
      }
    },
    // Features模块自动播放
    startAnimation() {
      this.intervalId =  setInterval(() => {
        this.currentBox = (this.currentBox + 1) % this.EcosystemList.length;
        for (let i in this.EcosystemList) {
        this.EcosystemList[i].show = true;
      }
        this.EcosystemList[this.currentBox].show = false;
      }, 1500);
    },

    handleMouseEcosystem(e) {
      clearInterval(this.intervalId)
       for (let i in this.EcosystemList) {
        this.EcosystemList[i].show = true;
      }
      
      this.EcosystemList[e].show = false;
    },
    handleMouseLeaveEcosystem(e) {
      this.EcosystemList[e].show = true;
      this.currentBox = e
      this.startAnimation()
    },
    handleMouseEnter(e) {
      this.bottomIconList[e].show = false;
    },
    handleMouseLeave(e) {
      this.bottomIconList[e].show = true;
    },
    intPie() {
      var chartDom = document.getElementById("pie_chart");
      var myChart = this.$Echarts.init(chartDom);
      var option;
      window.devicePixelRatio = 2;
      option = {
        tooltip: {
          show: true,
          trigger: "item",
          formatter: "{b}{d}%",
        },
        series: [
          {
            // name: 'Access From',
            type: "pie",
            radius: ["40%", "70%"],
            avoidLabelOverlap: false,
            itemStyle: {
              // borderRadius: 10,
              // borderWidth: 5
            },
            label: {
              show: false,
              position: "inside",
              formatter: "{d}%",
            },
            emphasis: {
              label: {
                show: false,
                fontSize: 40,
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: true,
            },
            data: [
              {
                value: 60000000,
                name: "Seed",
                itemStyle: { color: "#FF7B73" },
              },
              {
                value: 200000000,
                name: "Private",
                itemStyle: { color: "#FFE073" },
              },
              {
                value: 40000000,
                name: "Public ",
                itemStyle: { color: "#39BB6D" },
              },
              {
                value: 200000000,
                name: "Foundation",
                itemStyle: { color: "#6A39BB" },
              },
              {
                value: 100000000,
                name: "Community",
                itemStyle: { color: "#C930FF" },
              },
              {
                value: 140000000,
                name: "Marketing",
                itemStyle: { color: "#C9C21A" },
              },
              {
                value: 300000000,
                name: "Team",
                itemStyle: { color: "#3053FF" },
              },
              {
                value: 600000000,
                name: "Fitness To Earn",
                itemStyle: { color: "#B10145" },
              },
              {
                value: 160000000,
                name: "Ecosystem",
                itemStyle: { color: "#04FB8A" },
              },
              {
                value: 200000000,
                name: "Liquidity",
                itemStyle: { color: "#FE2B7C" },
              },
            ],
          },
        ],
      };

      myChart.setOption(option);
    },
    intLine() {
      var chartDom = document.getElementById("line_chart");
      var myChart = this.$Echarts.init(chartDom);
      var option;
      var formatter = "{b0}"; //展示百分比
      var j = 0;
      for (var k = 0; k < 9; k++) {
        //折线数不确定  假设有五条
        formatter += "<br/>{a" + j + "}: {c" + j + "}%";
        j++;
      }
      option = {
        tooltip: {
          trigger: "axis",
          formatter: formatter,
          axisPointer: {
            type: "none",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
        // toolbox: {
        //     feature: {
        //       saveAsImage: {}
        //     }
        //   },
        grid: {
          left: "0%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          axisLabel: {
            show: true,
            textStyle: {
              color: "#fff",
            },
            margin: 20,
          },
          axisTick: {
            show: false,
          },
          data: [
            "Mar 2022",
            "Feb 2023",
            "Feb 2024",
            "Feb 2025",
            "Feb 2026",
            "Feb 2027",
            "Jan 2028",
            "Jan 2029",
            "Jan 2030",
          ],
        },
        yAxis: {
          min: 0,
          max: 100,
          interval: 20,
          type: "value",
          axisLabel: {
            show: true,
            formatter: "{value} %",
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "#fff",
            },
          },
        },
        series: [
          {
            name: "Seed",
            type: "line",
            stack: "Total",
            symbol: "none",
            areaStyle: {
              color: "#FF7B73",
            },
            lineStyle: {
              width: 0,
            },
            emphasis: {
              focus: "series",
            },
            data: [0.3, 0.3, 0.84, 1.38, 3, 3, 3, 3, 3],
          },
          {
            name: "Private",
            type: "line",
            stack: "Total",
            symbol: "none",
            areaStyle: {
              color: "#FFE073",
            },
            lineStyle: {
              width: 0,
            },
            emphasis: {
              focus: "series",
            },
            data: [1, 1, 4, 7, 10, 10, 10, 10, 10],
          },
          {
            name: "Public",
            type: "line",
            stack: "Total",
            symbol: "none",
            areaStyle: {
              color: "#39BB6D",
            },
            lineStyle: {
              width: 0,
            },
            emphasis: {
              focus: "series",
            },
            data: [0.5, 2, 2, 2, 2, 2, 2, 2, 2],
          },
          {
            name: "Foundation",
            type: "line",
            stack: "Total",
            symbol: "none",
            areaStyle: {
              color: "#6A39BB",
            },
            lineStyle: {
              width: 0,
            },
            emphasis: {
              focus: "series",
            },
            data: [0, 0, 1, 2.5, 4, 5.5, 7, 8.5, 10],
          },
          {
            name: "Community",
            type: "line",
            stack: "Total",
            symbol: "none",

            lineStyle: {
              width: 0,
            },
            areaStyle: {
              color: "#C930FF",
            },
            emphasis: {
              focus: "series",
            },
            data: [0.41, 1.25, 2.5, 3.75, 5, 5, 5, 5, 5],
          },
          {
            name: "Marketing",
            type: "line",
            stack: "Total",
            symbol: "none",

            areaStyle: {
              color: "#C9C21A",
            },
            lineStyle: {
              width: 0,
            },
            emphasis: {
              focus: "series",
            },
            data: [0.84, 1.68, 2.52, 3.36, 4.2, 5.04, 5.88, 6.72, 7],
          },
          {
            name: "Team",
            type: "line",
            stack: "Total",
            symbol: "none",

            areaStyle: {
              color: "#3053FF",
            },
            lineStyle: {
              width: 0,
            },
            emphasis: {
              focus: "series",
            },
            data: [0, 0, 0, 0, 0, 3.75, 7.5, 11.25, 15],
          },
          {
            name: "Fitness To Earn",
            type: "line",
            stack: "Total",
            symbol: "none",

            areaStyle: {
              color: "#B10145",
            },
            lineStyle: {
              width: 0,
            },
            emphasis: {
              focus: "series",
            },
            data: [2.975, 6.5, 10.025, 13.55, 17.075, 20.6, 24.125, 27.65, 30],
          },
          {
            name: "Ecosystem",
            type: "line",
            stack: "Total",
            symbol: "none",

            areaStyle: {
              color: "#04FB8A",
            },
            lineStyle: {
              width: 0,
            },
            emphasis: {
              focus: "series",
            },
            data: [0, 1.2, 2.4, 3.6, 4.8, 6, 7.25, 8, 8],
          },
          {
            name: "Liquidity",
            type: "line",
            stack: "Total",
            symbol: "none",

            areaStyle: {
              color: "#FE2B7C",
            },
            lineStyle: {
              width: 0,
            },
            emphasis: {
              focus: "series",
            },
            data: [1.5, 2.35, 3.625, 4.9, 6.175, 7.45, 8.725, 10, 10],
          },
        ],
      };

      myChart.setOption(option);
    },
  },
};
</script>
<style scoped lang='scss'>
.content {
  // padding-top: 80px;
  background: #000;
  position: relative;
  width: 100%;
  overflow: hidden;
}
.Viewright {
  width: 88px;
  position: fixed;
  top: 157px;
  right: 0;
  animation: moveViewrightBox 6s ease-in-out infinite alternate;
}
.ViewrightBox{
  max-width: 100%;
  height: auto;
}
.circular{
  width: 135px;
  position: fixed;
  top: 550px;
  left:0;
  animation: moveViewrightBox 5s ease-in-out infinite alternate;
}
@keyframes moveViewrightBox {
      0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-80px);
    }
    100% {
      transform: translateY(0);
    }
  }
.typeArea {
  width: 1090px;
  margin: 0 auto;
}
.one {
  width: 100%;
  height: 120px;
  position: fixed;
  top: 0;
  left: 0;
  backdrop-filter: blur(10px);
  z-index: 100;
}
.typeArea-top {
  width: 100%;
  background: black;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
}
.top {
  width: 1090px;
  height: 80px;
  background: black;
  // position: fixed;
  // top: 0;
  // left: 0;
  color: #fff;
  z-index: 100;
  display: flex;
  // justify-content: space-between;
  // margin: 0 auto;
  // padding: 0 175px;
}
.loginBox {
  width: 90px;
  // height: 29px;
  margin-right: 149px;
  margin-top: 25px;
  // margin-left: 175px;
}
.logoBox{
  width: 100%;
  max-width: 100%;
  height: auto;
}
.top-right {
  display: flex;
  justify-content: space-between;
  margin-top: 23px;
  // margin-right: 175px;
}
.About {
  display: block;
  width: 118px;
  height: 39px;
  text-align: center;
  line-height: 38px;
  margin-left: 24px;
  cursor: pointer;
  color: #fff;
  font-size: 14px;
  font-family: 'twoSaira', Saira;
  // font-weight: 500;
  color: #ffffff;
}
.About-open {
  display: block;
  width: 118px;
  height: 39px;
  background: url("../assets/image/top-bg.png") no-repeat;
  background-size: 100% 100%;
  text-align: center;
  line-height: 38px;
  margin-left: 24px;
  cursor: pointer;
  color: #fff;
  font-family: 'twoSaira', Saira;
  font-weight: 500;
  color: #ffffff;
  font-size: 14px;
}
.fitnessBox {
  padding-top: 136px;
}
.fitnessBox-one {
  display: flex;
  justify-content: space-between;
  background: #000;
  margin-top: 80px;
  color: #fff;
  // margin-left: 175px;
}
.fitness_to_earn {
  width: 440px;
  height: 94px;
  font-size: 60px;
  // font-family: Saira-SemiBold Italic, Saira;
  font-family: 'oneSaira', Saira;
  font-weight: normal;
  color: #ffffff;
  line-height: 70px;
}
.text {
  width: 406px;
  height: 252px;
  margin-top: 36px;
  font-size: 18px;
  font-family: 'threeSaira', Saira;
  // font-weight: 300;
  color: #ffffff;
  line-height: 25px;
}
.downloadBox {
  display: flex;
  margin-top: 40px;
}
.appStoreBox{
  width: 227px;
  margin-right: 11px;
}
.appStoreImage{
  width: 100%;
  max-width: 100%;
  height: auto;
}
.appStoreBox:hover{
  animation: move .5 linear forwards;
}
.googleBox{
  width: 227px;
}
.googleImage{
  width: 100%;
  max-width: 100%;
  height: auto;
}
.googleBox:hover{
  animation: move .5 linear forwards;
}
@keyframes move{
  0%{
    transform: scale(1);
  }
  100%{
    transform: scale(1.1);
  }
}
.watchBox {
  margin-top: 30px;
  width: 620px;

}
.newWatch{
  width: 100%;
  max-width: 100%;
  height: auto;
}
//
.Ecosystem {
  background: #000;
  padding: 166px 0px 0px 0px;
}
.title {
  height: 94px;
  font-size: 60px;
  font-family: 'oneSaira', Saira;
  font-weight: normal;
  color: #ffffff;
  line-height: 70px;
  margin-bottom: 24px;
}
.features {
  height: 474px;
  display: flex;
  justify-content: space-between;
  margin-top: 52px;
  position: relative;
  border-bottom: 2px solid #2e9a8b;
}
.ecosyImageBox{
  width: 253px;
}
.ecosyImage{
  width: 100%;
  max-width: 100%;
  height: auto;
}

.features-top {
  width: 254px;
  height: 384px;
  background: url("../assets/image/FITWATCH.png") no-repeat;
  background-size: 100% 100%;
}
.features-topOpen {
  width: 254px;
  height: 384px;
  background: url("../assets/image/FITWATCH-OPEN.png") no-repeat;
  background-size: 100% 100%;
}
.features-topTwo {
  width: 254px;
  height: 384px;
  background: url("../assets/image/FitnessFI.png") no-repeat;
  background-size: 100% 100%;
}
.features-topTwoOpen {
  width: 254px;
  height: 384px;
  background: url("../assets/image/FitnessFI-open.png") no-repeat;
  background-size: 100% 100%;
}
.features-topThree {
  width: 254px;
  height: 384px;
  background: url("../assets/image/SocialFi.png") no-repeat;
  background-size: 100% 100%;
}
.features-topThreeOpen {
  width: 254px;
  height: 384px;
  background: url("../assets/image/SocialFi-open.png") no-repeat;
  background-size: 100% 100%;
}
.features-topFour {
  width: 254px;
  height: 384px;
  background: url("../assets/image/Character.png") no-repeat;
  background-size: 100% 100%;
}
.features-topFourOpen {
  width: 254px;
  height: 384px;
  background: url("../assets/image/Character-open.png") no-repeat;
  background-size: 100% 100%;
}
.EcosystemTitle {
  height: 25px;
  font-size: 16px;
  font-family: 'twoSaira', Saira;
  font-weight: normal;
  color: #ffffff;
  text-align: center;
  line-height: 25px;
  margin-top: 13px;
}
.EcosystemText {
  height: 95px;
  font-size: 12px;
  font-family: 'threeSaira', Saira;
  font-weight: 300;
  color: #ffffff;
  line-height: 19px;
  text-align: center;
  padding: 0 19px;
}
.EcosystemBottom {
  height: 3px;
  margin-top: 89px;
  // background: #44f4ae;
}
.EcosystemBottom-open {
  height: 3px;
  background: #44f4ae;
  margin-top: 89px;
  opacity: 1;
  z-index: 9999;
}
.wire {
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  height: 2px;
  background: #2e9a8b;
  color: #fff;
  z-index: 0;
  // margin-top: 90px;
}
//
.dowStarted {
  display: flex;
  justify-content: space-between;
  margin-top: 52px;
}
.r-icomn {
  padding-top: 68px;
}
.downlowMOJU {
  position: relative;
  width: 508px;
  height: 167px;
  opacity: 1;
  background: url("../assets/image/started.png") no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: space-between;
}
.downlowMOJU:hover{
  animation: dowMove 2s infinite;
}
@keyframes dowMove{
  0%, 100% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
}
.downlowMOJU-l {
  margin-top: 25px;
  margin-left: 25px;
}
.downlowMOJU-l-one {
  display: flex;
  height: 32px;
  line-height: 32px;
}
.numBox {
  position: relative;
  width: 32px;
  height: 32px;
  background: #90fbcf;
  opacity: 1;
  text-align: center;
  line-height: 32px;
  // box-shadow:5px 5px 5px rgba(169,222,238,0.2);
}
.numBoxPos {
  position: absolute;
  top: 5px;
  left: 5px;
  width: 32px;
  height: 32px;
  background: rgba(169, 222, 238, 0.2);
}
.dowTitle {
  font-size: 16px;
  font-family: 'twoSaira', Saira;
  font-weight: 600;
  color: #ffffff;
  margin-left: 12px;
}
.dowText {
  width: 228px;
  height: 57px;
  font-size: 12px;
  font-family: 'threeSaira', Saira;
  font-weight: 300;
  color: #ffffff;
  line-height: 20px;
  margin-top: 21px;
}
.appStoreBtn {
  width: 203px;
  margin-top: 26px;
}
.appStoreBtnImage{
  width: 100%;
  max-width: 100%;
  height: auto;
}
.gooleBtn {
   width: 203px;
  margin-top: 11px;
}
.gooleBtnImage{
  width: 100%;
  max-width: 100%;
  height: auto;
}
.downlowMOJU-l-TWO {
  margin-right: 26px;
}
.downlowMOJU-r {
  width: 67px;
  margin-top: 6px;
  margin-right: 70px;
}
.downlowMOJU-l-three {
  width: 166px;
  margin: 11px 31px 0 0;
}
.downlowMOJU-l-four {
  width: 115px;
  margin: 3px 63px 0 0;
}
//
.GameBox {
  padding: 175px 0px 0 0px;
}
.GameTitle {
  position: relative;
  height: 94px;
  font-size: 60px;
  font-family: 'oneSaira', Saira;
  font-weight: normal;
  color: #ffffff;
  line-height: 70px;
  margin-bottom: 24px;
}
.gamePosIcon {
  position: absolute;
  top: -20px;
  left: -115px;
}
.modesBox {
  margin-top: 52px;
  padding-top: 50px;
  overflow: hidden;
}
.CeshimodesBox {
  display: flex;
  justify-content: space-between;
  animation: moveAnimation 6s  ease-in-out infinite alternate;
}
@keyframes moveAnimation {
      0% {
      transform: translateX(0);
    }
    50% {
      transform: translateX(-217px);
    }
    100% {
      transform: translateX(0);
    }
  }
.soloModeBox {
  margin-left: 32px;
}
.modesBox .soloModeBox:nth-child(1) {
  margin-left: 0;
}
.soloModeBox-top {
  width: 300px;
  height: 166px;
  opacity: 1;
  background: url("../assets/image/modes.png") no-repeat;
  background-size: 100% 100%;
  overflow: hidden;
}
.soloImage {
  width: 300px;
}
.TaskBox{
  width: 242px;
}
.soloTitle {
  width: 300px;
  // width: 79px;
  height: 25px;
  font-size: 16px;
  font-family: 'twoSaira', Saira;
  font-weight: 600;
  color: #ffffff;
  line-height: 19px;
  text-align: center;
  margin: 20px 0;
}
.solotext {
  width: 275px;
  height: 121px;
  font-size: 12px;
  font-family: 'threeSaira', Saira;
  font-weight: 300;
  color: #ffffff;
  line-height: 18px;
  text-align: center;
}
.line-with-dots {
  position: relative;
  width: 300px;
  height: 2px; /* 设置直线的高度 */
  background: #00f5d4;
  margin-top: 24px;
}

.line-with-dots::before,
.line-with-dots::after {
  content: "";
  position: absolute;
  top: 1px;
  width: 3px; /* 设置圆点的直径 */
  height: 3px; /* 设置圆点的直径 */
  border-radius: 50%;
  background-color: #ffffff;
}

.line-with-dots::before {
  left: 0;
  transform: translate(-50%, -50%);
}

.line-with-dots::after {
  right: 0;
  transform: translate(50%, -50%);
}
.VersusImage {
  margin: 16px 81px;
}
.ChampionshipImage {
  margin: 13px 44px;
}
.TaskImage {
  margin: 6px 44px;
}
//
.videoBox {
  padding: 168px 0px 0 0px;
}
.playBox {
  width: 100%;
  height: 580px;
  position: relative;
}
.videBottom {
  width: 241px;
  position: absolute;
  bottom: -150px;
  right: -70px;
}
.view {
  width: 100%;
  height: 100%;
}
//
.mohuBox {
  padding-right: 175px;
  display: flex;
  justify-content: right;
  margin-top: -110px;
}
//
.theBox {
  display: flex;
  justify-content: space-between;
  // padding: 0px 175px 0 175px;
  padding: 166px 0px 0 0px;
}
.theText {
  width: 559px;
  height: 84px;
  font-size: 18px;
  font-family: 'threeSaira', Saira;
  font-weight: 300;
  color: #ffffff;
  line-height: 25px;
  margin-top: 40px;
}
.tmjcImage {
  width: 417px;
  position: relative;
}
.ballImage {
  width: 73px;
  position: absolute;
  top: 30px;
  left: -80px;
}
.NFTExplainBox {
  display: flex;
  justify-content: space-between;
  // padding: 0 175px;
  margin-top: -45px;
}
.NTF {
  width: 145px;
  height: 100px;
  opacity: 1;
  background: url("../assets/image/NFT.png") no-repeat;
  background-size: 100% 100%;
  // padding: 19px 0 0 19px;
}
.NTF:hover{
   animation: pulse 1.5s infinite;
}
@keyframes NFT{
  0%, 100% {
      transform: scale(1);
    }
    50% {
      transform: scale(0.5);
    }
}
.NTFText {
  width: 97px;
  height: 18px;
  font-size: 12px;
  font-family: 'threeSaira', Saira;
  font-weight: 300;
  color: #ffffff;
  line-height: 14px;
  margin-left: 19px;
}
.hammer {
  width: 26px;
  margin: 19px 0 10px 19px;
}
.TOKENOMICS {
  padding: 166px 0px 0 0px;
}
//
.diagram {
  display: flex;
  height: 463px;
  justify-content: space-between;
  margin-top: 52px;
}
.chart {
  width: 341px;
  height: 463px;
  margin-left: 75px;
}
#pie_chart {
  width: 100%;
  height: 100%;
  margin: auto;
}
.lineChart {
  width: 732px;
  height: 463px;
}
#line_chart {
  width: 100%;
  height: 100%;
}
.typeAreaBottom {
  width: 1090px;
  margin: 0 auto;
}
// biaoji
.pie_chart-buttom {
  width: 100%;
  // height: 200px;
  /* border: 1px solid red; */
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 35px;
}
.r-seed-box {
  display: flex;
  justify-content: space-between;
  width: 45%;
  color: #ffffff;
  font-weight: 400;
  height: 16px;
  line-height: 16px;
  margin-bottom: 28px;
}
.r-seed {
  width: 16px;
  height: 16px;
  background: #ff7b73;
  margin: auto;
  margin-right: 9px;
}
.r-seed-box1 {
  display: flex;
}
.r-private {
  background: #ffe073;
}
.r-public {
  background: #39bb6d;
}
.r-Foundation {
  background: #6a39bb;
}
.r-Community {
  background: #c930ff;
}
.r-Marketing {
  background: #c9c21a;
}
.r-Team {
  background: #3053ff;
}
.r-Move {
  background: #b10145;
}
.r-Ecosystem {
  background: #04fb8a;
}
.r-Liquidity {
  background: #fe2b7c;
}
.r-chart-buttom {
  margin-top: 59px;
  display: flex;
  justify-content: center;
  color: #fff;
}
.r-chart-buttom-box {
  margin-right: 26px;
}
//
.startedBox {
  padding: 166px 0px 0 0px;
}
.quicklyBox {
  width: 100%;
  height: 1210px;
  margin-top: 52px;
  background: url('../assets/image/map.png') no-repeat;
  background-size: 100% 100%;
}
.quicklyBoxConter{
  width: 1090px;
  margin: 0 auto;
  padding-top: 72px;
}
.quicklyImage {
  width: 100%;
}
//
.TeamBox {
  padding: 166px 0px 0 0px;
}
.CEOBox {
  display: flex;
  width: 527px;
  height: 123px;
  background: url("../assets/image/team.png") no-repeat;
  background-size: 100% 100%;
  margin-top: 32px;
}
.CEOBox:hover{
  animation: dowMove 1.5s infinite;
}
.ceoImage {
  width: 74px;
  margin-left: 27px;
  margin-top: 11px;
}
.characters {
  margin-left: 23px;
  margin-top: 15px;
}
.ceotitle {
  // width: 150px;
  height: 25px;
  font-size: 16px;
  font-family: 'twoSaira', Saira;
  font-weight: normal;
  color: #ffffff;
  line-height: 19px;
}
.ceoText {
  width: 380px;
  height: 38px;
  font-size: 12px;
  font-family: 'threeSaira', Saira;
  font-weight: 300;
  color: #ffffff;
  line-height: 14px;
}
.teamIntroduce {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
//
.bottomBigBox {
  padding-top: 134px;
  padding-bottom: 152px;
}
.bottomBox {
  // padding: 134px 0px 0 0;
  display: flex;
  justify-content: space-between;
  height: 111px;
}
.bottomBox-l {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 398px;
  height: 482px;
  background: url("../assets/image/bottom.png") no-repeat;
  background-size: 100% 100%;
  // overflow: hidden;
}
.bottomLoginImage {
  width: 181px;
  margin-top: 45px;
  // margin: 250px 42px 154px 175px;
}
.circulation {
  display: flex;
  justify-content: space-between;
  margin-top: 9px;
}
.circulation div {
  margin-right: 22px;
}
.Social {
  width: 59px;
  height: 28px;
  font-size: 14px;
  font-family: 'twoSaira', Sitara;
  font-weight: bold;
  color: #ffffff;
  line-height: 30px;
  margin-bottom: 20px;
  // margin-top: 214px;
}
.Social-bottom {
  display: flex;
  justify-items: center;
  height: 80px;
}
.bottomimage {
  width: 48px;
  // height: 80px;
}
.bottomimageopen {
  width: 48px;
  // height: 80px;
}
.social7{
   width: 60px;
}
.transi{
  width: 100%;
  max-width: 100%;
  height: auto;
}
//
.PrivacyBox {
  display: flex;
  justify-content: center;
  padding-bottom: 50px;
}
.Privacy {
  display: flex;
}
.reserved {
  color: #fff;
}
.APolicy {
  color: #fff;
}
.divisionBox{
  width: 311px;
  // height: 37px;
}
.divisionImage{
  width: 100%;
  max-width: 100%;
  height: auto;
}
</style>
